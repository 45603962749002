<template>
  <div>
    <title>SIM INVENTORY ~ PRODUCTION RESULT DATA</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Production Result Data
        <br />
        <h4>
          The following is a list of production results transaction data lists
        </h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <div class="col-xs-12">
          <router-link to="/add-proses-produksi">
            <button type="button" class="btn btn-info">
              <i class="fa fa-plus"> Add Data </i>
            </button>
          </router-link>
          <br /><br />
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list-ul"></i>
                <h3 class="box-title">Production Result Data</h3>
              </div>
              <div class="box-body">
                <div class="row">
                  <div class="col-xs-3">
                    Show &nbsp; :
                    <select
                      v-model="pageSize"
                      @change="handlePageSizeChange($event)"
                    >
                      <option
                        v-for="size in pageSizes"
                        :key="size"
                        :value="size"
                      >
                        {{ size }}
                      </option>
                    </select>
                    results
                  </div>
                  <div class="col-xs-5">
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search by Batch Code"
                      class="form-control"
                      v-model="namesearch"
                    />
                  </div>
                  <div class="col-xs-1">
                    <button
                      @click="searchDataProsesProduksi()"
                      type="button"
                      class="btn btn-info"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Batch Code</th>
                      <th>Order Number</th>
                      <th>Product Number</th>
                      <th>Part No</th>
                      <th>Process Name</th>
                      <th>Process Date</th>
                      <th>Process Results</th>
                      <th>Notes</th>
                      <th>Action</th>
                      <!-- <th>Detail</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <td>{{ classdata.nomor_produksi }}</td>
                      <td>{{ classdata.nomor_order }}</td>
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.part_no }}</td>
                      <td>{{ classdata.nama_proses }}</td>
                      <td>{{ classdata.tanggal_proses }}</td>
                      <td>{{ classdata.hasil_proses }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <!-- <router-link
                          :to="'/edit-prosesproduksi/' + classdata.id"
                        >
                          <button
                            title="Edit Production Process"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        &nbsp; -->

                        <router-link
                          :to="
                            '/proses-produksi-detail/' +
                            classdata.nomor_produksi
                          "
                        >
                          <button
                            title="View Detail Production Results"
                            class="btn btn-info"
                          >
                            <i class="fa fa-list-ul"></i>
                          </button>
                        </router-link>
                        &nbsp; <br><p></p>
                        <button
                          title="Delete Production Process"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>

                      <!-- <td>
                        <router-link
                          :to="
                            '/view-prosesproduksi-detailmaterial/' +
                            classdata.nomor_produksi
                          "
                        >
                          <button
                            title="View Production Process Material Details"
                            class="btn btn-warning"
                          >
                            <i class="fa fa-list-ul"></i>
                            Material
                          </button>
                        </router-link>
                        &nbsp; <br><p></p>
                        <router-link
                          :to="
                            '/view-prosesproduksi-detailfrom-prosesproduksi/' +
                            classdata.nomor_produksi
                          "
                        >
                          <button
                            title="See Detailed Production Process From Production"
                            class="btn btn-success"
                          >
                            <i class="fa fa-list-ul"></i> Production Process
                          </button>
                        </router-link>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange"
                />
              </div>
            </div>

            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Batch Code</th>
                      <th>Process Name</th>
                      <th>Process Date</th>
                      <th>Process Results</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="7" class="text-center">
                          <b style="color:red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>

      <!--
      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Filter data</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  Show &nbsp; :
                  <select
                    v-model="pageSize2"
                    @change="handlePageSizeChange2($event)"
                  >
                    <option
                      v-for="size2 in pageSizes2"
                      :key="size2"
                      :value="size2"
                    >
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Cari berdasarkan order number"
                    class="form-control"
                    v-model="namesearch2"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    @click="searchDataScheduleBuyerHariIni()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <i class="fa fa-list-ul"></i>
              <h3 class="box-title">Data Buyer Schedule Hari Ini</h3>
            </div>

            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Order Number</th>
                      <th>Tgl. Mulai</th>
                      <th>Tgl. Selesai</th>
                      <th>Catatan</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged2"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize2 }}</td>
                      <td>{{ classdata.order_number }}</td>
                      <td>{{ classdata.date_start }}</td>
                      <td>{{ classdata.date_end }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <router-link
                          :to="'/add-prosesproduksi/' + classdata.id"
                        >
                          <button
                            title="Tambah Proses Produksi"
                            class="btn btn-info"
                          >
                            <i class="fa fa-plus"></i> Tambah Proses Produksi
                          </button>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "klasifikasiartikel",
  components: {
    VPagination
  },
  data() {
    return {
      isShow: false,
      isExist: true,
      loading: false,
      namesearch: "",
      namesearch2: "",
      list_paged: [],
      list_paged2: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSize2: 10,
      pageSizes: [10, 50, 100, 500],
      pageSizes2: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchDataProsesProduksi();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.fetchDataProsesProduksi();
      } else {
        this.searchDataProsesProduksi();
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.fetchDataProsesProduksi();
      } else {
        this.searchDataProsesProduksi();
      }
    },

    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchDataProsesProduksi() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi/getalldataproses_produksi?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&kode_user=" +
        sessionStorage.getItem("kodeuser");
      console.log(urlAPIget);
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    searchDataProsesProduksi() {
      this.loading = true;
      const params = this.getRequestParams(this.page);
      //const params = this.getRequestParams(this.pagestat);
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi/getalldataproses_produksi?cari=" +
        this.namesearch +
        "&length=" +
        this.pageSize +
        "&kode_user=" +
        sessionStorage.getItem("kodeuser");
      axios
        .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },

    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl + "proses_produksi/hapusproses_produksi/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              });
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
